import React from 'react';
import './App.css';
import {Outlet} from "react-router-dom";
import {Breadcrumb} from "components/Navigation/Breadcrumb";
import {DialogButton} from "components/Modal/DialogButton";
import {LoginDialog} from "components/Account/LoginDialog";
import {clearToken} from "utilities/auth";
import {Button} from "antd";
import {useAuth} from "hooks/useAuth";
import {useJournal} from "hooks/useJournal";

function App() {
  const { user } = useAuth();
  const journal = useJournal();

  return (
    <div className={`App center-layout`}>
      <header className="App-header">
        <div className="App-header-user">
          { user && <>
              Logged in as {user.displayName}
              <div><Button type='link' onClick={clearToken}>Logout</Button></div>
          </> }
          { !user && <DialogButton dialog={props => <LoginDialog {...props} /> }>Login</DialogButton> }
        </div>
        <img src={`/${journal.code}logo.png`} className="App-logo" alt="logo" />
        <div className="App-header-text">
          <h1>{journal.name}</h1>
          <h2>Foundation Compositio Mathematica</h2>

          <Breadcrumb />
        </div>
      </header>
      <Outlet />
    </div>
  );
}

export default App;
